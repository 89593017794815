<template>
    <v-sheet elevation="4" >
         <v-row>
            <v-col>
                    <v-text-field
                    :rules="[
                    (v) => !!v || 'Es requerido asignar un nombre al campo',
                    ]"
                    required
                    v-model="$store.state.meta_data_store[index].name"
                    rounded
                    dense
                    filled
                    prepend-icon="mdi-book"
                    label="Nombre del campo"
                    
                ></v-text-field>
            </v-col>
            <v-col>
                <v-select 
                    :append-outer-icon="'mdi-delete'"
                    @click:append-outer="$store.state.meta_data_store.splice(index, 1)"
                    :rules="[
                    (v) => !!v || 'Es requerido tipo al campo',
                    ]"
                    label="Tipo del campo" 
                    v-model="$store.state.meta_data_store[index].type" 
                    item-value="value" item-text="name" 
                    filled rounded dense 
                    :items="[{name:'Texto',value: 'string'},{name:'Fecha',value:'date'}]" 
                >

                </v-select>
            </v-col>
        </v-row>

    </v-sheet>
   
    
</template>
<script>
export default {
    props: {
        index: {
            type: Number,
            required: true
        }
    }
}
</script>